<template>
  <div>
    <b-card class="mb-1">
      <b-form @submit.prevent="getData()">
        <div class="form-row">
          <div class="col-md-1">
            <div class="form-group mb-md-0">
              <input
                type="tel"
                v-model="filters.id"
                placeholder="ID"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <input
                type="tel"
                v-model="filters.company"
                placeholder="Fornecedor"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="filters.status"
                placeholder="Status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsBankAccounts"
                v-model="filters.bank_account"
                placeholder="Conta bancária"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.period"
                class="form-control"
                placeholder="Período de vencimento"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
          <div class="col-md-1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor"></i>
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>

    <div
      v-if="$can('Aprovado - Cadastrar Conta Bancária', 'Financeiro - ADM')"
      class="form-row justify-content-end mb-2"
    >
      <div class="col-md-3">
        Selecione os pedidos de compra para cadastrar a conta bancária.
      </div>
      <div class="col-md-2">
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          @click="openModal"
          class="btn btn-sm btn-success btn-block"
        >
          <i
            style="font-size: 1rem; line-height: 1.5; margin-right: 5px"
            class="bi bi-wallet2 cursor-pointer cursor"
          ></i>
          Conta bancária
        </button>
      </div>
    </div>

    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template
          v-if="$can('Aprovado - Cadastrar Conta Bancária', 'Financeiro - ADM')"
          #head(select)
        >
          <b-form-checkbox
            v-model="selectAllCheckbox"
            @change="toggleSelectAll"
          />
        </template>

        <template
          v-if="$can('Aprovado - Cadastrar Conta Bancária', 'Financeiro - ADM')"
          #cell(select)="{ item }"
        >
          <b-form-checkbox
            v-if="!item.bank_account"
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto"
            :value="item.uuid"
            v-model="selectedItems"
          />
        </template>

        <template #cell(supplier)="{ item }">
          <div class="strategic-units" v-if="item.supplier">
            <strong>{{ item.supplier.company_name }}</strong>
            <div>{{ item.supplier.document }}</div>
          </div>
          <div class="strategic-units" v-else>
            <strong>{{ item.customer.title }}</strong>
            <div>{{ toMaskCpf(item.customer.cpf) }}</div>
          </div>
        </template>

        <template #cell(bank_account)="{ item }">
          <span v-if="item.bank_account">{{ item.bank_account.title }}</span>
        </template>
        <template #cell(plan)="{ item }">
          <div v-if="item.plan" class="d-block text-center">
            <strong>{{ item.plan.title }}</strong>
            <div v-if="item.document_type">{{ item.document_type.title }}</div>
          </div>
          <span v-else-if="item.order_id" class="text-danger">
            <strong class="d-block">Matrícula</strong>
            Cancelada
          </span>
        </template>

        <template #cell(total)="{ item }">
          <div
            style="
              display: grid;
              align-content: center;
              align-items: center;
              grid-gap: 3px;
            "
          >
            <span>
              {{ item.total | toCurrency }}
            </span>
            <strong class="d-block" :class="getStatus(item.status.title)">
              {{ item.status.title }}
            </strong>
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-between" style="gap: 15px">
            <router-link
              v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')"
              :to="{
                name: 'orders-purchases-print',
                params: { uuid: item.uuid },
              }"
              class="d-flex align-items-center"
            >
              <feather-icon icon="EyeIcon" class="cursor-pointer" size="15" />
            </router-link>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-modal
      id="modal-create-order-purchase-approved-purchases"
      modal-class="modal-create"
      :title="'Vincular conta bancária'"
      centered
      hide-footer
    >
      <b-form @submit.prevent="submitUpdatePayment">
        <!-- <b-card-actions action-collapse class="card-purchase-order" title="Pedido de compra"
            sub-title="Detalhes sobre o pedido de compra selecionado." v-if="item.purchase_order_id">
            <div class="form-row mt-2">
              <div class="col-md-12">
                <div class="form-group">
                  <strong>Fornecedor</strong>: {{ item.supplier.title }}
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <strong>Documento</strong>: {{ item.purchase_order_document_number }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <strong>ID PC.</strong>: {{ item.purchase_order_id }}
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <strong>Data</strong>: {{ item.created_at | datePT() }}
                </div>
              </div>
              <div class="col-md-7">
                <div class="form-group">
                  <strong>Parcelamento</strong>: {{ item.installments }}x de {{ item.total | toCurrency }}
                </div>
              </div>
              <div v-if="item.description" class="col-md-12">
                <strong>Observação</strong>: {{ item.description }}
              </div>
            </div>

            <p
              style="font-size: 0.9rem; margin-bottom: 0px; margin-top: 10px; border-top: 1px solid #f5f5f5; padding-top: 15px;">
              <strong>Responsável: </strong>: {{ item.user.title }}
            </p>
          </b-card-actions>

          <h5>Detalhes do pagamento</h5>

          <BCardText>
            <div class="form-row mt-2">
              <div class="col-md-2">
                <div class="form-group">
                  <strong>ID</strong>: {{ item.id }}
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <strong>Pago em</strong>: {{ item.paidAt | datePT() }}
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <strong>Vencimento</strong>: {{ item.date | datePT() }}
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <strong>Valor da Parcela</strong>: {{ item.total | toCurrency }}
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <strong>Parcela</strong>: {{ item.installment }} de {{ item.installments }}
                </div>
              </div>
              <div v-if="item.note" class="col-md-12">
                <strong>Observação</strong>: {{ item.note }}
              </div>
            </div>
          </BCardText> -->
        <div class="form-row mt-2">
          <div class="col-md-12">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Conta bancária
            </label>
            <div class="form-group">
              <v-select
                label="title"
                :searchable="false"
                item-text="title"
                item-value="code"
                :options="optionsBankAccounts"
                v-model="formModal.bank_account"
                placeholder=""
                :class="{ 'is-invalid': $v.formModal.bank_account.$error }"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button
              type="button"
              @click="
                $bvModal.hide('modal-create-order-purchase-approved-purchases')
              "
              class="btn btn-light float-left"
            >
              Fechar
            </button>
            <ButtonsActionsFooter
              variant="success"
              :submited="submited"
              :text="'Atualizar'"
              subtext="Aguarde..."
            />
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  VBTooltip,
  BForm,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";

import { required } from "vuelidate/lib/validators";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import { maskCpf } from "@/_helpers/_urlHelper";

import { getStatusPurchaseOrderStatus } from "@/_helpers/_item";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    VBTooltip,
    CustomPaginateTable,
    ButtonsActionsFooter,
    BCardActions,
    BForm,
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      selectedItems: [],
      selectAllCheckbox: false,
      submitedFilter: false,
      submited: false,
      filters: {
        id: "",
        document_number: "",
        document_type: "",
        step: "",
        company: "",
        status: "",
        period: "",
        currentPage: 1,
        perPage: 100,
      },
      formModal: {
        note: "",
        document_number: "",
        document_type: "",
        total: "",
        bank_account: "",
        status: "",
        uuid: "",
      },
      textSubmit: "",
      item: {
        supplier: "",
        bank: "",
        plan: "",
        status: "",
        note: "",
        installment: "",
        installments: "",
        date: "",
        category: "",
        document_number: "",
        document_type: "",
        total: "",
        uuid: "",
        purchase_order_id: "",
      },
      optionsStatus: [],
      optionsSteps: [],
      optionsDocumentTypes: [],
      optionsBankAccounts: [],
      optionsBankAccountsFilter: [],
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "select",
          label: "",
          class: "text-center",
          thStyle: { width: "20px" },
        },
        {
          key: "id",
          label: "ID",
          class: "text-center",
          thStyle: { width: "50px" },
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: { width: "40px" },
        },
        { key: "supplier", label: "Fornecedor", class: "text-left" },
        {
          key: "plan",
          label: "Histórico PC",
          class: "text-center",
          thStyle: { width: "250px" },
        },
        {
          key: "description",
          label: "Histórico",
          class: "text-center",
          thStyle: { width: "250px" },
        },
        {
          key: "bank_account",
          label: "Conta Bancária",
          class: "text-center",
          thStyle: { width: "250px" },
        },
        {
          key: "total",
          label: "Total",
          class: "text-center",
          thStyle: { width: "130px" },
        },
        {
          key: "created_at",
          label: "Cadastro",
          class: "text-center",
          thStyle: { width: "130px" },
        },
      ],
      dataItems: [],
    };
  },
  validations: {
    formModal: {
      bank_account: {
        required,
      },
    },
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-order-purchase-approved-purchases");
    this.$root.$off("paginate:update");
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  watch: {
    selectedItems(newVal) {
      if (newVal.length === 0 && this.dataItems.length === 0) {
        this.selectAllCheckbox = false;
      } else {
        this.selectAllCheckbox = newVal.length === this.dataItems.length;
      }
    },
  },
  methods: {
    getStatus(name) {
      return getStatusPurchaseOrderStatus(name);
    },
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems.map((item) => item.uuid)
        : [];
    },
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    async submitUpdatePayment() {
      this.$v.formModal.$touch();

      if (!this.$v.formModal.$error) {
        const dataResource = {
          bank_account: this.formModal.bank_account.code,
          items: this.selectedItems,
        };

        this.submited = true;

        this.$store
          .dispatch("OrderPurchase/updatePurchaseOrderStep", dataResource)
          .then((response) => {
            this.notifyDefault("success");
            this.getData();
            this.selectedItems = [];
            this.$v.formModal.$reset();
            this.$bvModal.hide(
              "modal-create-order-purchase-approved-purchases"
            );

            if (response.moviment && response.moviment.id) {
              window.open(
                this.$router.resolve({
                  name: "orders-purchases-romaneio-view",
                  params: { uuid: response.moviment.id },
                }).href,
                "_blank"
              );
            }
          })
          .finally(() => {
            this.submited = false;
          });
      }
    },
    openModal(item = null) {
      if (item) {
        this.formModal = {
          uuid: item.uuid,
          bank_account: item.bank_account,
        };
        this.selectedItems.push(item.uuid);
      } else {
        this.formModal = {
          note: "",
          document_number: "",
          document_type: "",
          total: "",
          bank_account: "",
        };
      }

      this.$bvModal.show("modal-create-order-purchase-approved-purchases");
    },
    confirmDisapprove(uuid) {
      this.$swal({
        title: "Reprovar pedido de compra",
        text: "Você tem certeza que deseja reprovar o pedido de compra?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, quero reprovar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("OrderPurchase/updatePurchaseOrderStep", {
              uuid,
              step: 1,
            })
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Pedido repovado!",
                text: "Operação realizada com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    async getData() {
      this.submitedFilter = true;

      const filtersData = {
        status: this.filters.status ? JSON.stringify(this.filters.status) : "",
        bank_account: this.filters.bank_account
          ? JSON.stringify(this.filters.bank_account)
          : "",
        company: this.filters.company,
        period: this.filters.period,
        id: this.filters.id,
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
        exportData: 0,
      };

      this.$store
        .dispatch("OrderPurchase/allApprovedPurchases", filtersData)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
  },
  async mounted() {
    this.getData();

    this.$store.dispatch("OrderPurchase/forSelectSteps").then((res) => {
      this.optionsSteps = res;
    });

    this.$store.dispatch("OrderPurchase/forSelectStatus").then((res) => {
      this.optionsStatus = res;
    });

    this.$store.dispatch("OrderPurchase/forSelectDocumentTypes").then((res) => {
      this.optionsDocumentTypes = res;
    });

    this.$store.dispatch("BankAccount/forSelect", { term: "" }).then((res) => {
      this.optionsBankAccounts = res;
      this.optionsBankAccountsFilter = res;
      this.optionsBankAccountsFilter.push({
        code: 0,
        title: "Nenhuma",
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.strategic-units {
  align-items: center;
  display: grid;
  align-content: center;
  justify-content: left;
  display: block;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
