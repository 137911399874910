var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("div", { staticClass: "form-group mb-md-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.id,
                          expression: "filters.id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel", placeholder: "ID" },
                      domProps: { value: _vm.filters.id },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "id", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group mb-md-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.company,
                          expression: "filters.company",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel", placeholder: "Fornecedor" },
                      domProps: { value: _vm.filters.company },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "company", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            searchable: false,
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsStatus,
                            placeholder: "Status",
                          },
                          model: {
                            value: _vm.filters.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "status", $$v)
                            },
                            expression: "filters.status",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            searchable: false,
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsBankAccounts,
                            placeholder: "Conta bancária",
                          },
                          model: {
                            value: _vm.filters.bank_account,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "bank_account", $$v)
                            },
                            expression: "filters.bank_account",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Período de vencimento",
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.submitedFilter
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("i", {
                              staticClass: "bi bi-search cursor-pointer cursor",
                            }),
                          ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.$can("Aprovado - Cadastrar Conta Bancária", "Financeiro - ADM")
        ? _c("div", { staticClass: "form-row justify-content-end mb-2" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _vm._v(
                " Selecione os pedidos de compra para cadastrar a conta bancária. "
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-block",
                  attrs: {
                    disabled: _vm.selectedItems.length < 1 ? true : false,
                    type: "button",
                  },
                  on: { click: _vm.openModal },
                },
                [
                  _c("i", {
                    staticClass: "bi bi-wallet2 cursor-pointer cursor",
                    staticStyle: {
                      "font-size": "1rem",
                      "line-height": "1.5",
                      "margin-right": "5px",
                    },
                  }),
                  _vm._v(" Conta bancária "),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u(
              [
                _vm.$can(
                  "Aprovado - Cadastrar Conta Bancária",
                  "Financeiro - ADM"
                )
                  ? {
                      key: "head(select)",
                      fn: function () {
                        return [
                          _c("b-form-checkbox", {
                            on: { change: _vm.toggleSelectAll },
                            model: {
                              value: _vm.selectAllCheckbox,
                              callback: function ($$v) {
                                _vm.selectAllCheckbox = $$v
                              },
                              expression: "selectAllCheckbox",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                _vm.$can(
                  "Aprovado - Cadastrar Conta Bancária",
                  "Financeiro - ADM"
                )
                  ? {
                      key: "cell(select)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          !item.bank_account
                            ? _c("b-form-checkbox", {
                                staticStyle: {
                                  "margin-left": "13px",
                                  padding: "0px",
                                  width: "20px",
                                  margin: "auto",
                                },
                                attrs: { value: item.uuid },
                                model: {
                                  value: _vm.selectedItems,
                                  callback: function ($$v) {
                                    _vm.selectedItems = $$v
                                  },
                                  expression: "selectedItems",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(supplier)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.supplier
                        ? _c("div", { staticClass: "strategic-units" }, [
                            _c("strong", [
                              _vm._v(_vm._s(item.supplier.company_name)),
                            ]),
                            _c("div", [_vm._v(_vm._s(item.supplier.document))]),
                          ])
                        : _c("div", { staticClass: "strategic-units" }, [
                            _c("strong", [_vm._v(_vm._s(item.customer.title))]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.toMaskCpf(item.customer.cpf))),
                            ]),
                          ]),
                    ]
                  },
                },
                {
                  key: "cell(bank_account)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.bank_account
                        ? _c("span", [_vm._v(_vm._s(item.bank_account.title))])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(plan)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.plan
                        ? _c("div", { staticClass: "d-block text-center" }, [
                            _c("strong", [_vm._v(_vm._s(item.plan.title))]),
                            item.document_type
                              ? _c("div", [
                                  _vm._v(_vm._s(item.document_type.title)),
                                ])
                              : _vm._e(),
                          ])
                        : item.order_id
                        ? _c("span", { staticClass: "text-danger" }, [
                            _c("strong", { staticClass: "d-block" }, [
                              _vm._v("Matrícula"),
                            ]),
                            _vm._v(" Cancelada "),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(total)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "grid",
                            "align-content": "center",
                            "align-items": "center",
                            "grid-gap": "3px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("toCurrency")(item.total)) +
                                " "
                            ),
                          ]),
                          _c(
                            "strong",
                            {
                              staticClass: "d-block",
                              class: _vm.getStatus(item.status.title),
                            },
                            [_vm._v(" " + _vm._s(item.status.title) + " ")]
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(actions)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-between",
                          staticStyle: { gap: "15px" },
                        },
                        [
                          _vm.$can("Lançado - Visualizar", "Financeiro - ADM")
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "d-flex align-items-center",
                                  attrs: {
                                    to: {
                                      name: "orders-purchases-print",
                                      params: { uuid: item.uuid },
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: { icon: "EyeIcon", size: "15" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-create-order-purchase-approved-purchases",
            "modal-class": "modal-create",
            title: "Vincular conta bancária",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitUpdatePayment.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row mt-2" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                    _vm._v(" Conta bancária "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-select",
                        {
                          class: {
                            "is-invalid": _vm.$v.formModal.bank_account.$error,
                          },
                          attrs: {
                            label: "title",
                            searchable: false,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsBankAccounts,
                            placeholder: "",
                          },
                          model: {
                            value: _vm.formModal.bank_account,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "bank_account", $$v)
                            },
                            expression: "formModal.bank_account",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-end modal-footer-custom",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-7 col-12" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light float-left",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide(
                                "modal-create-order-purchase-approved-purchases"
                              )
                            },
                          },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                      _c("ButtonsActionsFooter", {
                        attrs: {
                          variant: "success",
                          submited: _vm.submited,
                          text: "Atualizar",
                          subtext: "Aguarde...",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }